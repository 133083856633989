export const environment = {
  app: {
    title: "CodeMonkey",
    apiUrl: "https://api.bhminvestments.com",
  },
  notification: {
    apiKey: "OwHZ0SQM0fxsH3Tgan7apBM86",
    apiUrl: "https://notifications.codemonkey.com.mx:3001"
  }
};
