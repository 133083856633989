import { Component } from '@angular/core';
import { DynamicPagesService } from '@services/dynamic-pages.service';

@Component({
  selector: 'page-dynamic-pages-list',
  templateUrl: './dynamic-pages-list.component.html',
  styleUrl: './dynamic-pages-list.component.scss'
})
export class DynamicPagesListPage {
  public isLoading = true;

  constructor(
    private dynamicPagesService: DynamicPagesService
  ) { }

  async ngOnInit() {
    this.refresh();
  }

  refresh = () => {
    this.isLoading = true;
    this.getPages();
  }

  public pages: any = [];
  getPages = async () => {
    await this.dynamicPagesService.getList()
      .then((response) => this.pages = response)

    this.isLoading = false
  }
}
