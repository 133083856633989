import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogsService } from '@services/catalogs.service';
import { UserService } from '@services/user.service';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';
import { ToastService } from '@utils/toast.service';

@Component({
  selector: 'page-user-view',
  templateUrl: './user-view.component.html',
  styleUrl: './user-view.component.scss'
})
export class UserViewPage {
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private toast: ToastService,
    private catalogsService: CatalogsService,
    private confirmDialogService: ConfirmDialogService,
    private title: Title
  ) { }

  public isLoading = false;
  public isSaving = false;
  public isDeleting = false;

  public username!: string;
  public user: any = null;
  public permissions: any = [];

  public formUser = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    phone: [null],
    permissionId: [null, Validators.required],
    password: [null, Validators.minLength(8)],
    rpassword: [null, Validators.minLength(8)],
  });

  get email() { return this.formUser.get('email') }
  set email(value: any) { this.formUser.get('email')?.setValue(value) }
  get firstName() { return this.formUser.get('firstName') }
  set firstName(value: any) { this.formUser.get('firstName')?.setValue(value) }
  get lastName() { return this.formUser.get('lastName') }
  set lastName(value: any) { this.formUser.get('lastName')?.setValue(value) }
  get phone() { return this.formUser.get('phone') }
  set phone(value: any) { this.formUser.get('phone')?.setValue(value) }
  get permissionId() { return this.formUser.get('permissionId') }
  set permissionId(value: any) { this.formUser.get('permissionId')?.setValue(value) }

  ngOnInit() {
    this.isLoading = true;

    this.activatedRoute.params.subscribe(async routeParams => {
      this.username = routeParams['username']

      this.title.setTitle('Viendo usuario: ' + this.username + ' ' + this.title.getTitle());

      this.catalogsService.permissions()
        .then((response: any) => this.permissions = response)

      await this.userService.getByUsername(this.username)
        .then((response: any) => {
          if (response.content.length <= 0) {
            this.router.navigate(['/users'])
            this.toast.error('Usuario no encontrado.')
          }

          this.user = response.content
        })

      this.email = this.user.email;
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.phone = this.user.phone;
      this.permissionId = this.user.permissionId;

      this.isLoading = false;
    });
  }

  save = async () => {
    this.isSaving = true;

    await this.userService.save(this.username, this.formUser.value)
      .then((response) => this.toast.success('Usuario actualizado con éxito.'))
      .catch((error: any) => this.toast.error(error.error.data.message))

    this.isSaving = false;
  }

  delete = (event: Event) => {
    this.isDeleting = true;

    this.confirmDialogService.dialog('¿Está seguro de eliminar este usuario?', 'Continuar', 'Cancelar')
      .then(() => {
        this.userService.delete(this.username)
          .then(() => {
            this.toast.success('Usuario eliminado con éxito.')
            this.router.navigate(['/users'])
          })
          .catch((error: any) => {
            this.toast.error(error.error.data.message)
            this.isDeleting = false
          })
      })
      .catch(() => this.isDeleting = false)
  }
}
