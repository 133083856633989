import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RealEstateCreatePageRoutingModule } from './realestate-create-routing.module';
import { RealEstateCreatePage } from './realestate-create.component';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { HeaderPageTitleBoxComponent } from '@components/headers/page-title-box/page-title-box.component';
import { PlaceholderCardFormVerticalComponent } from '@components/placeholders/card-form-vertical/card-form-vertical.component';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { PreviewUploadImageComponent } from '@components/preview-upload-image/preview-upload-image.component';
import { ChipsModule } from 'primeng/chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputNumberModule } from 'primeng/inputnumber';
import { EditorModule } from 'primeng/editor';
import { KeyFilterModule } from 'primeng/keyfilter';

@NgModule({
  declarations: [
    RealEstateCreatePage
  ],
  imports: [
    CommonModule,
    RealEstateCreatePageRoutingModule,
    BackboneComponent,
    RouterModule,
    HeaderPageTitleBoxComponent,
    PlaceholderCardFormVerticalComponent,
    InputTextModule,
    ButtonModule,
    PreviewUploadImageComponent,
    ChipsModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    InputNumberModule,
    EditorModule,
    KeyFilterModule
  ]
})
export class RealEstateCreatePageModule { }
