import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageModule } from './login/login.module';
import { LockScreenPageModule } from './lock-screen/lock-screen.module';
import { HomePageModule } from './home/home.module';
import { UserModule } from './user/user.module';
import { DynamicPagesModule } from './dynamic-pages/dynamic-pages.module';
import { BlogModule } from './blog/blog.module';
import { GalleryModule } from './gallery/gallery.module';
import { RealEstateModule } from './realestate/realestate.module';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LoginPageModule,
    LockScreenPageModule,
    HomePageModule,
    UserModule,
    DynamicPagesModule,
    BlogModule,
    GalleryModule,
    RealEstateModule
  ],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
