import { Component } from '@angular/core';
import { CatalogsService } from '@services/catalogs.service';
import { RealestateService } from '@services/realestate.service';

@Component({
  selector: 'page-realestate-list',
  templateUrl: './realestate-list.component.html',
  styleUrl: './realestate-list.component.scss'
})
export class RealEstateListPage {
  constructor(
    private realestateService: RealestateService,
    private catalogsService: CatalogsService
  ) { }

  public isLoading = true;
  public typeProperties: any[] = []
  public statusProperties: any[] = []
  public states: any[] = []
  public municipalities: any[] = []

  async ngOnInit() {
    this.realestateService.getTypeList()
      .then((response: any) => this.typeProperties = response.content)

    this.realestateService.getStatusList()
      .then((response: any) => this.statusProperties = response.content)

    this.catalogsService.states()
      .then((response: any) => this.states = response)

    this.catalogsService.municipalities()
      .then((response: any) => this.municipalities = response)

    this.refresh();
  }

  refresh = () => {
    this.isLoading = true;
    this.getProperties();
  }

  public properties: any = [];
  getProperties = async () => {
    await this.realestateService.getList()
      .then((response) => this.properties = response)

    this.isLoading = false
  }

  findType = (id: string) => this.typeProperties.find((type) => type.propertyTypeId === id)
  findStatus = (id: string) => this.statusProperties.find((status) => status.propertyStatusId === id)
  findState = (code: string) => this.states.find((state) => state.code === code)
  findMunicipality = (id: string) => this.municipalities.find((municipality) => municipality.stateMunicipalityId === id)
}
