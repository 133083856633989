import { Component } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DynamicPagesService } from '@services/dynamic-pages.service';
import { ToastService } from '@utils/toast.service';
import { UtilsService } from '@utils/utils.service';

@Component({
  selector: 'page-dynamic-pages-create',
  templateUrl: './dynamic-pages-create.component.html',
  styleUrl: './dynamic-pages-create.component.scss'
})
export class DynamicPagesCreatePage {
  constructor(
    private formBuilder: FormBuilder,
    private dynamicPagesService: DynamicPagesService,
    private toast: ToastService,
    private router: Router,
    public utils: UtilsService
  ) { }

  public isLoading = false;
  public isSaving = false;

  public formPage = this.formBuilder.group({
    title: [null, [Validators.required]],
    url: [null],
    type: [null, [Validators.required]],
    hero: [null, [Validators.required]],
    metadata: this.formBuilder.group({
      hero: [null],
      title: [null],
      description: [null],
      keywords: [null]
    }),
    sections: this.formBuilder.array([])
  });

  get title() { return this.formPage.get('title') }
  set title(value: any) { this.title.setValue(value) }
  get url() { return this.formPage.get('url') }
  set url(value: any) { this.url.setValue(value) }
  get type() { return this.formPage.get('type') }
  set type(value: any) { this.type.setValue(value) }
  get hero() { return this.formPage.get('hero') }
  set hero(value: any) { this.hero.setValue(value) }

  get metadata() { return this.formPage.get('metadata') }
  get metadataHero() { return this.metadata?.get('hero') }
  set metadataHero(value: any) { this.metadataHero?.setValue(value) }
  get metadataTitle() { return this.metadata?.get('title') }
  set metadataTitle(value: any) { this.metadataTitle?.setValue(value) }
  get metadataDescription() { return this.metadata?.get('description') }
  set metadataDescription(value: any) { this.metadataDescription?.setValue(value) }
  get metadataKeywords() { return this.metadata?.get('keywords') }
  set metadataKeywords(value: any) { this.metadataKeywords?.setValue(value) }

  get sections(): FormArray { return this.formPage.controls["sections"] as FormArray; }
  sectionContent = (index: number): FormArray => { return this.sections.controls[index] as FormArray; }

  public typePages: any[] = [
    { value: 'home', label: 'Inicio' },
    { value: 'portfolio', label: 'Portafolio' },
    { value: 'services', label: 'Servicios' },
    { value: 'other', label: 'Otro' },
  ]

  public typePageSections: any[] = [
    { value: 'input', label: 'Campo de texto.' },
    { value: 'textarea', label: 'Campo de texto grande.' },
    { value: 'editor', label: 'Editor de texto.' },
  ]

  public formAddSections: { type: string | null, name: string | null }[] = []

  onHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.hero = event.target.files[0];
    }
  }

  onMetadataHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.metadataHero = event.target.files[0];
    }
  }

  pushSection = () => {
    this.sections.push(this.formBuilder.array([]))
    this.formAddSections.push({ type: null, name: null })
  }

  removeSection = (index: number) => {
    this.sections.removeAt(index)
    this.formAddSections.splice(index, 1)
  }

  pushContent = (index: number) => {
    const section: FormArray = this.sections.controls[index] as FormArray;
    section.push(this.formBuilder.group({
      type: [this.formAddSections[index].type, Validators.required],
      name: [this.formAddSections[index].name, Validators.required],
      value: [null]
    }))

    this.formAddSections[index].type = null;
    this.formAddSections[index].name = null;
  }

  removeContent = (index: number, indexContent: number) => {
    const section: FormArray = this.sections.controls[index] as FormArray;

    section.removeAt(indexContent)
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('title', this.title.value)
    myFormData.append('url', this.url.value)
    myFormData.append('type', this.type.value)
    myFormData.append('hero', this.hero.value)

    myFormData.append('metadataHero', this.metadataHero.value)
    myFormData.append('metadataTitle', this.metadataTitle.value)
    myFormData.append('metadataDescription', this.metadataDescription.value)
    myFormData.append('metadataKeywords', this.metadataKeywords.value)

    myFormData.append('sections', JSON.stringify(this.sections.value))

    await this.dynamicPagesService.create(myFormData)
      .then(() => {
        this.toast.success('Página creada exitosamente.')
        this.router.navigate(['/pages'])
      })
      .catch(() => this.toast.error('Error al crear la página.'))

    this.isSaving = false;
  }
}
