import { Component } from '@angular/core';
import { BlogService } from '@services/blog.service';

@Component({
  selector: 'page-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrl: './blog-list.component.scss'
})
export class BlogListPage {
  constructor(
    private blogService: BlogService
  ) { }

  public isLoading = true;

  async ngOnInit() {
    this.refresh();
  }

  refresh = () => {
    this.isLoading = true;
    this.getBlogs();
  }

  public blogs: any = [];
  getBlogs = async () => {
    await this.blogService.getList().then((response) => this.blogs = response)

    this.isLoading = false
  }
}
