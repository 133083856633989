import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from '@services/blog.service';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';
import { ToastService } from '@utils/toast.service';

@Component({
  selector: 'page-blog-view',
  templateUrl: './blog-view.component.html',
  styleUrl: './blog-view.component.scss'
})
export class BlogViewPage {
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private titlePage: Title,
    private toast: ToastService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    private blogService: BlogService
  ) { }

  public isLoading = false;
  public isSaving = false;
  public isDeleting = false;

  public separatorExp: RegExp = /,| /;
  public blog: any

  public formBlog = this.formBuilder.group({
    title: [null, Validators.required],
    description: [null, Validators.required],
    hero: [null],
    content: [null],
    tags: [null, Validators.required],
    seoDescription: [null],
  });

  get title() { return this.formBlog.get('title') }
  set title(value: any) { this.title.setValue(value) }
  get description() { return this.formBlog.get('description') }
  set description(value: any) { this.description.setValue(value) }
  get hero() { return this.formBlog.get('hero') }
  set hero(value: any) { this.hero.setValue(value) }
  get content() { return this.formBlog.get('content') }
  set content(value: any) { this.content.setValue(value) }
  get tags() { return this.formBlog.get('tags') }
  set tags(value: any) { this.tags.setValue(value) }
  get seoDescription() { return this.formBlog.get('seoDescription') }
  set seoDescription(value: any) { this.seoDescription.setValue(value) }

  public onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      this.hero = event.target.files[0];
    }
  }

  ngOnInit() {
    this.isLoading = true;

    this.activatedRoute.params.subscribe(async routeParams => {
      await this.blogService.getBlog(routeParams['blog'])
        .then((response: any) => this.blog = response.content)

      if (this.blog.length === 0) {
        this.router.navigate(['/blog'])
        this.toast.error('La entrada al blog, no existe.')
      } else {
        this.titlePage.setTitle('Editando la entrada al blog: ' + this.blog.title + ' - ' + this.titlePage.getTitle());

        this.title.setValue(this.blog.title);
        this.description.setValue(this.blog.description);
        this.content.setValue(this.blog.content);
        this.tags.setValue(this.blog.tags);
        this.seoDescription.setValue(this.blog.seoDescription);

        this.isLoading = false;
      }
    });
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('title', this.title.value)
    myFormData.append('description', this.description.value)
    myFormData.append('content', this.content.value)
    myFormData.append('tags', this.tags.value)
    myFormData.append('hero', this.hero.value)
    myFormData.append('seoDescription', this.seoDescription.value)

    await this.blogService.save(this.blog.url, myFormData)
      .then((response) => this.toast.success('Entrada al blog, guardada exitosamente.'))
      .catch((error: any) => this.toast.error(error.error.data.message))

    this.isSaving = false;
  }

  delete = (event: Event) => {
    this.isDeleting = true;

    this.confirmDialogService.dialog('¿Está seguro de eliminar esta entrada al blog?', 'Continuar', 'Cancelar')
      .then(() => {
        this.isLoading = true

        this.blogService.delete(this.blog.url)
          .then(() => {
            this.toast.success('Entrada al blog eliminada exitosamente.')
            this.router.navigate(['/blog'])
          })
          .catch((error: any) => {
            this.toast.error(error.error.data.message)
            this.isDeleting = false
            this.isLoading = false
          })
      })
      .catch(() => this.isDeleting = false)
  }
}
