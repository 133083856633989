import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CatalogsService } from '@services/catalogs.service';
import { UserService } from '@services/user.service';
import { ToastService } from '@utils/toast.service';

@Component({
  selector: 'page-user-create',
  templateUrl: './user-create.component.html',
  styleUrl: './user-create.component.scss'
})
export class UserCreatePage {
  constructor(
    private formBuilder: FormBuilder,
    private catalogsService: CatalogsService,
    private userService: UserService,
    private toast: ToastService,
    private router: Router
  ) { }

  public isLoading = false;
  public isSaving = false;

  public permissions: any = [];

  public formUser = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    phone: [null],
    permissionId: [null, Validators.required],
    password: [null, [Validators.required, Validators.minLength(8)]],
    rpassword: [null, [Validators.required, Validators.minLength(8)]],
  });

  get email() { return this.formUser.get('email') }
  set email(value: any) { this.formUser.get('email')?.setValue(value) }
  get firstName() { return this.formUser.get('firstName') }
  set firstName(value: any) { this.formUser.get('firstName')?.setValue(value) }
  get lastName() { return this.formUser.get('lastName') }
  set lastName(value: any) { this.formUser.get('lastName')?.setValue(value) }
  get phone() { return this.formUser.get('phone') }
  set phone(value: any) { this.formUser.get('phone')?.setValue(value) }
  get permissionId() { return this.formUser.get('permissionId') }
  set permissionId(value: any) { this.formUser.get('permissionId')?.setValue(value) }
  get password() { return this.formUser.get('password') }
  set password(value: any) { this.formUser.get('password')?.setValue(value) }
  get rpassword() { return this.formUser.get('rpassword') }
  set rpassword(value: any) { this.formUser.get('rpassword')?.setValue(value) }

  async ngOnInit() {
    this.isLoading = true;

    await this.catalogsService.permissions()
      .then((response: any) => this.permissions = response)

    this.isLoading = false;
  }

  save = async () => {
    this.isSaving = true;

    await this.userService.create(this.formUser.value)
      .then(() => {
        this.toast.success('Cuenta de usuario creada exitosamente.')
        this.router.navigate(['/users'])
      })
      .catch(() => this.toast.error('Error al crear la cuenta de usuario.'))

    this.isSaving = false;
  }
}
