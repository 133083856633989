import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class SearchComponent {
  @Input() theme: string = 'dark';
  @Input() currentPage: string = '';
  public txtSearchBy: string = 'Buscar';
  public sizeModal: string = '';

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() { }

  open = (content: TemplateRef<any>) => {
    this.modalService.open(content, { ariaLabelledBy: 'modal-search', size: this.sizeModal }).result
      .then((result) => { }, (reason) => { });
  }
}
