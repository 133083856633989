import { Component } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GalleryService } from '@services/gallery.service';
import { ToastService } from '@utils/toast.service';

@Component({
  selector: 'page-gallery-create',
  templateUrl: './gallery-create.component.html',
  styleUrl: './gallery-create.component.scss'
})
export class GalleryCreatePage {
  constructor(
    private formBuilder: FormBuilder,
    private galleryService: GalleryService,
    private toast: ToastService,
    private router: Router
  ) { }

  public isLoading = false;
  public isSaving = false;

  public form = this.formBuilder.group({
    name: [null, [Validators.required]],
    description: [null],
    images: this.formBuilder.array([])
  });

  get name() { return this.form.get('name') }
  set name(value: any) { this.name.setValue(value) }
  get description() { return this.form.get('description') }
  set description(value: any) { this.description.setValue(value) }

  get images(): FormArray { return this.form.controls["images"] as FormArray; }
  getImageAsIndex = (index: number) => this.images.controls[index].get('file')

  pushImage = () => this.images.push(this.formBuilder.group({ file: [null] }))
  removeImage = (index: number) => {
    this.images.removeAt(index);

    if (this.images.value <= 0) {
      this.pushImage()
    }
  }

  ngOnInit() {
    this.pushImage()
  }

  onInputFileChanged = (event: any, index: number) => {
    if (event.target.files && event.target.files.length) {
      this.images.controls[index].get('file')?.setValue(event.target.files[0]);
    }
  }

  create = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('name', this.name.value)
    myFormData.append('description', this.description.value)

    for (const [index, item] of this.images.value.entries()) {
      myFormData.append(`image_${index}`, item.file)
    }

    await this.galleryService.create(myFormData)
      .then(() => {
        this.toast.success('Galería creada correctamente.')
        this.router.navigate(['/galleries'])
      })
      .catch(() => this.toast.error('Error al crear la galería.'))

    this.isSaving = false;
  }
}
