import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MainShortcutsService } from './main-shortcuts.service';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';

@Component({
  selector: 'sidebar-main-shortcuts',
  templateUrl: './main-shortcuts.component.html',
  styleUrl: './main-shortcuts.component.scss',
  standalone: true,
  imports: [
    RouterModule,
    NgbTooltipModule,
    ShowForPermissionsDirective
  ]
})
export class SidebarMainShortcutsComponent {
  constructor(
    private mainShortcutsService: MainShortcutsService
  ) { }

  public lockscreen = () => {
    this.mainShortcutsService.lockscreen()
  }
}
