<backbone [currentPage]="'galleries-create'">
  <header-page-title-box title="Crear una nueva galería"
    [links]="[{title: 'Galerías',  link: '/galleries'}, {title: 'Crear galería'}]">
    <div class="d-flex gap-2" buttons>
      <p-button size="small" icon="pi pi-save" label="Crear" [loading]="isSaving" (onClick)="create()"
        [disabled]="!form.valid"></p-button>
    </div>
  </header-page-title-box>
  <div class="p-3">
    @if (this.isLoading === true) {
    <placeholder-card-form-vertical />
    }
    @else {
    <form [formGroup]="form" [ngClass]="{'opacity-50 pointer-events user-select-none': this.isSaving === true}">
      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información principal de la galería.</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 d-flex flex-column gap-2">
            <input formControlName="name" placeholder="Nombre" type="text" pInputText class="w-100 h-50px" />
            <small class="ps-2">Asígnale un nombre. <span class="text-danger">Requerido *</span></small>
          </div>
          <div class="d-flex flex-column gap-2">
            <input formControlName="description" placeholder="Descripción" type="text" pInputText
              class="w-100 h-50px" />
            <small class="ps-2">(Opcional) Puedes escribir una pequeña descripción, para identificar la galería.</small>
          </div>
        </div>
      </div>

      <div class="row">
        @for (item of this.images.controls; track item; let index = $index) {
        <div formArrayName="images" class="col-md-3 mb-4">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-body">
              <div class="d-flex flex-column gap-2">
                <preview-upload-image [image]="getImageAsIndex(index)?.value"
                  imageSrc="./assets/images/media-placeholder.png">
                  <input #input (change)="onInputFileChanged($event, index)" class="form-control h-50px" type="file"
                    style="line-height: 2.6;">
                </preview-upload-image>
                <small class="ps-2 pt-1 d-block">Formatos JPG, JPEG y PNG. Tamaño máximo 2mb. <span
                    class="text-danger">Requerido *</span></small>
              </div>

              <div class="end-0 position-absolute top-0" style="margin: -10px;">
                <p-button icon="pi pi-trash" severity="danger" [rounded]="true" size="small"
                  (click)="removeImage(index)"></p-button>
              </div>
            </div>
          </div>
        </div>
        }

        <div class="col-md-3 mb-4">
          <div (click)="pushImage()"
            class="button-add-image align-items-center d-flex flex-column h-100 justify-content-center w-100 border-2 border rounded-2 py-5"
            style="border-style: dashed !important;" role="button">
            <div class="opacity-25">
              <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-plus-lg"
                viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </form>
    }
  </div>
</backbone>