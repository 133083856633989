import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RealEstateListPageRoutingModule } from './realestate-list-routing.module';
import { RealEstateListPage } from './realestate-list.component';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { PlaceholderTableComponent } from '@components/placeholders/table/table.component';
import { HeaderPageTitleBoxComponent } from '@components/headers/page-title-box/page-title-box.component';
import { ButtonModule } from 'primeng/button';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';

@NgModule({
  declarations: [
    RealEstateListPage
  ],
  imports: [
    CommonModule,
    RealEstateListPageRoutingModule,
    BackboneComponent,
    PlaceholderTableComponent,
    HeaderPageTitleBoxComponent,
    ButtonModule,
    ShowForPermissionsDirective
  ]
})
export class RealEstateListPageModule { }
