<ng-template #content let-modal>
  <div class="modal-body pb-0">
    <form class="border border-2 border-primary rounded-2 d-flex align-items-center px-3 bg-white" autocomplete="off">
      <label for="input-search" class="fs-4 text-primary">
        <i class="bi bi-search"></i>
      </label>
      <input id="input-search" type="search" class="form-control border-0 fs-4" placeholder="{{this.txtSearchBy}}"
        aria-label="Search" aria-describedby="input-search" style="height: 50px;">
    </form>
    <div class="content py-4">
      <p class="m-0 d-flex justify-content-center align-items-center text-uppercase fw-medium opacity-50"
        style="min-height: 100px;">
        <small>Sin resultados</small>
      </p>
    </div>
  </div>
  <div class="modal-footer justify-content-between border-0">
    <ul class="list-unstyled m-0">
      <li class="list-inline-item text-body-tertiary">
        <small class="align-items-center d-flex"><kbd class="me-1">↵</kbd> para buscar</small>
      </li>
      <li class="list-inline-item text-body-tertiary">
        <small class="align-items-center d-flex"><kbd class="me-1">Esc</kbd> para cerrar</small>
      </li>
    </ul>

    <div>
      <p class="m-0"><small>Powered by CodeMonkey</small></p>
    </div>
  </div>
</ng-template>

<button type="button"
  class="btn btn-{{theme}} h-100 rounded-circle d-flex flex-column align-items-center justify-content-center p-0"
  (click)="open(content)" style="width: 40px; height: 40px !important;">
  <ng-content></ng-content>
</button>