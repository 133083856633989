@if (imageSrc) {
<div class="image rounded-2 overflow-hidden" (click)="click()" role="button">
  @if (this.isLoading === true) {
  <p-skeleton width="100%" height="100%"></p-skeleton>
  }
  @else {
  <figure class="w-100 h-100 m-0">
    <img [src]="imageSrc" alt="preview" class="w-100 h-100 m-0 object-fit-cover" />
  </figure>

  <div class="hover">
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-upload"
      viewBox="0 0 16 16">
      <path
        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
      <path
        d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
    </svg>
  </div>
  }
</div>
}
<div class="d-none">
  <ng-content></ng-content>
</div>