import { Component } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogsService } from '@services/catalogs.service';
import { RealestateService } from '@services/realestate.service';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';
import { ToastService } from '@utils/toast.service';
import { UtilsService } from '@utils/utils.service';

@Component({
  selector: 'page-realestate-view',
  templateUrl: './realestate-view.component.html',
  styleUrl: './realestate-view.component.scss'
})
export class RealEstateViewPage {
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private toast: ToastService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    public utils: UtilsService,
    private realestateService: RealestateService,
    private catalogsService: CatalogsService
  ) { }

  public isLoading = false;
  public isSaving = false;
  public isDeleting = false;

  public typeProperties: any[] = []
  public statusProperties: any[] = []
  public states: any[] = []
  public municipalities: any[] = []
  public currencies: any[] = [
    { value: 'USD', label: 'Dólares americanos' },
    { value: 'MXN', label: 'Pesos mexicanos' },
    { value: 'EUR', label: 'Euros' }
  ]

  public titlePage = '';
  public data: any = null;

  public form = this.formBuilder.group({
    name: [null, [Validators.required]],
    foil: [null, [Validators.required]],
    hero: [null],
    type: [null, [Validators.required]],
    status: [null, [Validators.required]],
    state: [null],
    municipality: [null],
    city: [null],
    address: [null],
    zipcode: [null],
    squareMetres: [0],
    bedrooms: [0],
    bathrooms: [0],
    floors: [0],
    currency: ['MXN'],
    price: [null],
    content: [null],
    metadata: this.formBuilder.group({
      hero: [null],
      title: [null],
      description: [null],
      keywords: [null]
    }),
    gallery: this.formBuilder.array([])
  });

  get name() { return this.form.get('name') }
  set name(value: any) { this.name.setValue(value) }
  get foil() { return this.form.get('foil') }
  set foil(value: any) { this.foil.setValue(value) }
  get hero() { return this.form.get('hero') }
  set hero(value: any) { this.hero.setValue(value) }
  get type() { return this.form.get('type') }
  set type(value: any) { this.type.setValue(value) }
  get status() { return this.form.get('status') }
  set status(value: any) { this.status.setValue(value) }
  get state() { return this.form.get('state') }
  set state(value: any) { this.state.setValue(value) }
  get municipality() { return this.form.get('municipality') }
  set municipality(value: any) { this.municipality.setValue(value) }
  get city() { return this.form.get('city') }
  set city(value: any) { this.city.setValue(value) }
  get address() { return this.form.get('address') }
  set address(value: any) { this.address.setValue(value) }
  get zipcode() { return this.form.get('zipcode') }
  set zipcode(value: any) { this.zipcode.setValue(value) }
  get squareMetres() { return this.form.get('squareMetres') }
  set squareMetres(value: any) { this.squareMetres.setValue(value) }
  get bedrooms() { return this.form.get('bedrooms') }
  set bedrooms(value: any) { this.bedrooms.setValue(value) }
  get bathrooms() { return this.form.get('bathrooms') }
  set bathrooms(value: any) { this.bathrooms.setValue(value) }
  get floors() { return this.form.get('floors') }
  set floors(value: any) { this.floors.setValue(value) }
  get currency() { return this.form.get('currency') }
  set currency(value: any) { this.currency.setValue(value) }
  get price() { return this.form.get('price') }
  set price(value: any) { this.price.setValue(value) }
  get content() { return this.form.get('content') }
  set content(value: any) { this.content.setValue(value) }

  get metadata() { return this.form.get('metadata') }
  get metadataHero() { return this.metadata?.get('hero') }
  set metadataHero(value: any) { this.metadataHero?.setValue(value) }
  get metadataTitle() { return this.metadata?.get('title') }
  set metadataTitle(value: any) { this.metadataTitle?.setValue(value) }
  get metadataDescription() { return this.metadata?.get('description') }
  set metadataDescription(value: any) { this.metadataDescription?.setValue(value) }
  get metadataKeywords() { return this.metadata?.get('keywords') }
  set metadataKeywords(value: any) { this.metadataKeywords?.setValue(value) }

  get gallery(): FormArray { return this.form.controls["gallery"] as FormArray; }
  getImageAsIndex = (index: number): any => {
    if (typeof this.gallery.controls[index].get('file')?.value === 'string') {
      return {
        value: this.gallery.controls[index].get('file')?.value,
        flag: true
      }
    } else {
      return this.gallery.controls[index].get('file')
    }
  }

  ngOnInit() {
    this.isLoading = true;

    this.realestateService.getTypeList()
      .then((response: any) => this.typeProperties = response.content)

    this.realestateService.getStatusList()
      .then((response: any) => this.statusProperties = response.content)

    this.catalogsService.states()
      .then((response: any) => this.states = response)

    this.activatedRoute.params.subscribe(async routeParams => {
      await this.realestateService.getProperty(routeParams['id'])
        .then((response: any) => this.data = response.content)

      if (this.data.length === 0) {
        this.router.navigate(['/realestate'])
        this.toast.error('La propiedad no existe.')
      } else {
        this.titlePage = this.data.name;
        this.titleService.setTitle('Viendo la propiedad: ' + this.titlePage + ' - ' + this.titleService.getTitle());

        this.name = this.data.name;
        this.foil = this.data.foil;
        this.type = this.data.typeId;
        this.status = this.data.statusId;
        this.state = this.data.stateCode;
        if (this.data.stateCode !== null) await this.onChangeState();
        this.municipality = this.data.municipalityId;
        this.city = this.data.city;
        this.address = this.data.address;
        this.zipcode = this.data.zipcode;
        this.squareMetres = this.data.squareMetre;
        this.bedrooms = this.data.bedroom;
        this.bathrooms = this.data.bathroom;
        this.floors = this.data.floor;
        this.currency = this.data.currency;
        this.price = this.data.price;
        this.content = this.data.content;

        if (this.data.metadata.title !== null) {
          this.metadataTitle = this.data.metadata.title;
        }

        if (this.data.metadata.description !== null) {
          this.metadataDescription = this.data.metadata.description;
        }

        if (this.data.metadata.keywords !== null) {
          this.metadataKeywords = this.data.metadata.keywords.split(',');
        }

        if (this.data.gallery !== null && this.data.gallery.length > 0) {
          for (const [index, item] of this.data.gallery.entries()) {
            this.gallery.push(this.formBuilder.group({ file: [item.uri] }))
          }
        } else {
          this.pushImage()
        }

        this.isLoading = false;
      }
    });
  }

  onHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.hero = event.target.files[0];
    }
  }

  onMetadataHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.metadataHero = event.target.files[0];
    }
  }

  onChangeState = async () => {
    this.municipality = null;
    await this.catalogsService.municipality(this.state.value)
      .then((response: any) => this.municipalities = response)
  }

  pushImage = () => this.gallery.push(this.formBuilder.group({ file: [null] }))
  removeImage = (index: number) => {
    this.gallery.removeAt(index);

    if (this.gallery.value <= 0) {
      this.pushImage()
    }
  }

  onInputFileChanged = (event: any, index: number) => {
    if (event.target.files && event.target.files.length) {
      this.gallery.controls[index].get('file')?.setValue(event.target.files[0]);
    }
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('name', this.name.value)
    myFormData.append('foil', this.foil.value)
    myFormData.append('hero', this.hero.value)
    myFormData.append('type', this.type.value)
    myFormData.append('status', this.status.value)
    myFormData.append('state', this.state.value)
    myFormData.append('municipality', this.municipality.value)
    myFormData.append('city', this.city.value)
    myFormData.append('address', this.address.value)
    myFormData.append('zipcode', this.zipcode.value)
    myFormData.append('squareMetres', this.squareMetres.value)
    myFormData.append('bedrooms', this.bedrooms.value)
    myFormData.append('bathrooms', this.bathrooms.value)
    myFormData.append('floors', this.floors.value)
    myFormData.append('currency', this.currency.value)
    myFormData.append('price', this.price.value)
    myFormData.append('content', this.content.value)

    myFormData.append('metadataHero', this.metadataHero.value)
    myFormData.append('metadataTitle', this.metadataTitle.value)
    myFormData.append('metadataDescription', this.metadataDescription.value)
    myFormData.append('metadataKeywords', this.metadataKeywords.value)

    for (const [index, item] of this.gallery.value.entries()) {
      myFormData.append(`gallery_${index}`, item.file)
    }

    await this.realestateService.update(this.data.foil, myFormData)
      .then(() => this.toast.success('La propiedad se actualizó.'))
      .catch((error: any) => this.toast.error('Error al actualizar la propiedad.'))

    this.isSaving = false;
  }

  delete = (event: Event) => {
    this.isDeleting = true;

    this.confirmDialogService.dialog('¿Está seguro de eliminar esta propiedad?', 'Continuar', 'Cancelar')
      .then(() => {
        this.isLoading = true;

        this.realestateService.delete(this.data.foil)
          .then(() => {
            this.toast.success('La propiedad se eliminó.')
            this.router.navigate(['/realestate'])
          })
          .catch((error: any) => {
            this.toast.error(error.error.data.message)
            this.isDeleting = false;
            this.isLoading = false;
          })
      })
      .catch(() => this.isDeleting = false)
  }
}
