import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DynamicPagesListPageModule } from './dynamic-pages-list/dynamic-pages-list.module';
import { DynamicPagesCreatePageModule } from './dynamic-pages-create/dynamic-pages-create.module';
import { DynamicPagesViewPageModule } from './dynamic-pages-view/dynamic-pages-view.module';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    DynamicPagesListPageModule,
    DynamicPagesCreatePageModule,
    DynamicPagesViewPageModule
  ],
  exports: [RouterModule]
})
export class DynamicPagesRoutingModule { }
