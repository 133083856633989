import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RealEstateCreatePageModule } from './realestate-create/realestate-create.module';
import { RealEstateListPageModule } from './realestate-list/realestate-list.module';
import { RealEstateViewPageModule } from './realestate-view/realestate-view.module';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    RealEstateCreatePageModule,
    RealEstateListPageModule,
    RealEstateViewPageModule
  ],
  exports: [RouterModule]
})
export class RealEstateRoutingModule { }
