import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DropdownUserAccountComponent } from '@components/dropdowns/user-account/user-account.component';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';

@Component({
  selector: 'sidebar-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss',
  standalone: true,
  imports: [
    DropdownUserAccountComponent,
    RouterModule,
    ShowForPermissionsDirective
  ]
})
export class SidebarMainMenuComponent {
}
