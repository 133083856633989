import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  constructor(
    private http: HttpService
  ) { }

  public getList = async () => await this.http.get(`/api/v1/gallery`)
    .then((response: any) => response.data)

  public getGallery = async (id: string) => await this.http.get(`/api/v1/gallery/${id}?by=gallery`)
    .then((response: any) => response.data)

  public create = async (body: any) => await this.http.post(`/api/v1/gallery`, body)
    .then((response: any) => response)

  public update = async (id: string, body: any) => await this.http.put(`/api/v1/gallery/${id}?by=gallery`, body)
    .then((response: any) => response)

  public delete = async (id: string) => await this.http.delete(`/api/v1/gallery/${id}?by=gallery`)
    .then((response: any) => response.data)
}
