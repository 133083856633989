<backbone [currentPage]="'dynamic-pages-create'">
  <header-page-title-box title="Crear nueva página"
    [links]="[{title: 'Páginas',  link: '/pages'}, {title: 'Crear página'}]">
    <div class="d-flex gap-2" buttons>
      <p-button size="small" icon="pi pi-save" label="Crear" [loading]="isSaving" (onClick)="save()"
        [disabled]="!formPage.valid"></p-button>
    </div>
  </header-page-title-box>
  <div class="p-3">
    @if (this.isLoading === true) {
    <placeholder-card-form-vertical />
    }
    @else {
    <form [formGroup]="formPage" [ngClass]="{'opacity-50 pointer-events user-select-none': this.isSaving === true}">
      <div class="row">
        <div class="col-md-8">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Información principal de la página.</h6>
            </div>
            <div class="card-body">
              <div class="mb-3 d-flex flex-column gap-2">
                <input placeholder="Título de la página" formControlName="title" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="ps-2">Escribe un título para tu página. <span class="text-danger">Requerido
                    *</span></small>
              </div>
              <div class="mb-3 d-flex flex-column gap-2">
                <input placeholder="URL, Ejem: mi-primer-pagina" formControlName="url" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="ps-2">(Opcional) Puedes escribir la url personalizada para tu página, si esta vacío, se
                  asignara una url segun el título de la página.</small>
              </div>
              <div class="d-flex flex-column gap-2">
                <ng-select formControlName="type" [placeholder]="'Tipo de página.'" [clearable]="false"
                  class="w-100 h-50px">
                  @for (item of this.typePages; track $index) {
                  <ng-option [value]="item.value">{{ item.label }}</ng-option>
                  }
                </ng-select>
                <small class="ps-2">Selecciona un esquema de página. <span class="text-danger">Requerido
                    *</span></small>
              </div>
            </div>
          </div>

          @if (this.sections.controls.length > 0){
          @for (item of this.sections.controls; track item; let index = $index) {
          <div formArrayName="sections" class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Bloque de página</h6>
              <p-button icon="bi bi-trash-fill" severity="danger" [text]="true" size="large" styleClass="p-0"
                (click)="removeSection(index)"></p-button>
            </div>
            <div class="card-body" [formGroupName]="index">
              <div class="mb-3 d-flex justify-content-between gap-2">
                <ng-select [(ngModel)]="this.formAddSections[index].type" [ngModelOptions]="{standalone: true}"
                  placeholder="Tipo de sección" class="w-50 h-50px">
                  @for (item of this.typePageSections; track $index) {
                  <ng-option [value]="item.value">{{ item.label }}</ng-option>
                  }
                </ng-select>
                <input [(ngModel)]="this.formAddSections[index].name" [ngModelOptions]="{standalone: true}" pInputText
                  placeholder="Nombre de la sección" type="text" class="w-100 h-50px" />

                <p-button icon="pi pi-plus" label="Agregar" styleClass="h-50px"
                  [disabled]="this.formAddSections[index].type === null || this.formAddSections[index].name === null"
                  (click)="pushContent(index)"></p-button>
              </div>

              @for (itemContent of sectionContent(index).controls; track itemContent; let indexContent = $index) {
              <div class="mb-3 d-flex justify-content-between gap-2" [formGroupName]="indexContent">
                @if (itemContent.get('type')?.value === 'input') {
                <input formControlName="value" pInputText placeholder="{{itemContent.get('name')?.value}}" type="text"
                  class="w-100 h-50px" />
                }

                @if (itemContent.get('type')?.value === 'textarea') {
                <textarea formControlName="value" pInputTextarea placeholder="{{itemContent.get('name')?.value}}"
                  class="w-100" rows="6"></textarea>
                }

                @if (itemContent.get('type')?.value === 'editor') {
                <p-editor formControlName="value" placeholder="{{itemContent.get('name')?.value}}" class="w-100"
                  [style]="{ height: '320px' }"></p-editor>
                }

                <p-button icon="bi bi-trash-fill" severity="danger" [text]="true" size="large" styleClass="p-0"
                  (click)="removeContent(index, indexContent)"></p-button>
              </div>
              }
            </div>
          </div>
          }
          }

          <div class="mb-4 border-2 border rounded-2" style="border-style: dashed !important;">
            <p-button label="{{'Nuevo Bloque' | uppercase}}" [text]="true" [plain]="true" styleClass="w-100 h-50px"
              (click)="pushSection()"></p-button>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Imágen de portada.</h6>
            </div>
            <div class="card-body">
              <div class="d-flex flex-column gap-2">
                <preview-upload-image [image]="this.hero.value" imageSrc="./assets/images/media-placeholder.png">
                  <input #input (change)="onHeroChanged($event)" class="form-control h-50px" type="file"
                    style="line-height: 2.6;">
                </preview-upload-image>
                <small class="ps-2 pt-1 d-block">Formatos JPG, JPEG y PNG. Tamaño máximo 2mb. <span
                    class="text-danger">Requerido *</span></small>
              </div>
            </div>
          </div>

          <div formGroupName="metadata" class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Metadata.</h6>
            </div>
            <div class="card-body">
              <p class="mb-4">Esta información es especifica para los buscadores y compartir el contenido en redes
                sociales.</p>

              <div class="mb-3 d-flex flex-column gap-2">
                <preview-upload-image [image]="this.metadataHero.value"
                  imageSrc="./assets/images/media-placeholder.png">
                  <input #input (change)="onMetadataHeroChanged($event)" class="form-control h-50px" type="file"
                    style="line-height: 2.6;">
                </preview-upload-image>
                <small class="ps-2 pt-1 d-block">Formatos JPG, JPEG y PNG. Tamaño máximo 2mb.</small>
              </div>
              <div class="mb-3 d-flex flex-column gap-2">
                <input placeholder="Título de la página." formControlName="title" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="px-2">Escribe un título.</small>
              </div>
              <div class="mb-3 d-flex flex-column gap-2">
                <input placeholder="Descripción." formControlName="description" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="px-2">Escribe una breve descripción.</small>
              </div>
              <div class="d-flex flex-column gap-2">
                <p-chips placeholder="Palabras clave." formControlName="keywords" styleClass="d-block"
                  inputStyleClass="d-block w-100" [separator]="this.utils.separatorExp"></p-chips>
                <small class="px-2">Escribe palabras clave para los motores de busqueda.</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    }
  </div>
</backbone>