<backbone [currentPage]="'realestate-view'">
  <header-page-title-box title="Propiedad: {{this.titlePage}}."
    [links]="[{title: 'Realestate',  link: '/realestate'}, {title: this.titlePage}]">
    <div class="d-flex gap-2" buttons>
      @if (this.data !== null) {
      <p-button size="small" icon="pi pi-save" label="Guardar" [loading]="isSaving" (onClick)="save()"
        [disabled]="!form.valid"></p-button>
      <p-button *showForPermissions="['delete_realestate']" size="small" icon="pi pi-trash" severity="danger"
        [loading]="isDeleting" (onClick)="delete($event)"></p-button>
      }
    </div>
  </header-page-title-box>
  <div class="p-3">
    @if (this.isLoading === true) {
    <placeholder-card-form-vertical />
    }
    @else {
    <form [formGroup]="form" [ngClass]="{'opacity-50 pointer-events user-select-none': this.isSaving === true}">
      <div class="row">
        <div class="col-md-8">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Información principal de la propiedad.</h6>
            </div>
            <div class="card-body">
              <div class="mb-3 d-flex flex-column gap-2">
                <input placeholder="Nombre de la propiedad" formControlName="name" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="ps-2">Escribe un nombre para la propiedad. <span class="text-danger">Requerido
                    *</span></small>
              </div>
              <div class="mb-3 d-flex flex-column gap-2">
                <input placeholder="Folio de la propiedad" formControlName="foil" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="ps-2">Escribe un folio para la propiedad. <span class="text-danger">Requerido
                    *</span></small>
              </div>
              <div class="d-flex gap-2">
                <div class="d-flex flex-column gap-2 w-50">
                  <ng-select formControlName="type" placeholder="Tipo de propiedad" [clearable]="false"
                    class="w-100 h-50px">
                    @for (item of this.typeProperties; track $index) {
                    <ng-option [value]="item.propertyTypeId">{{ item.label }}</ng-option>
                    }
                  </ng-select>
                  <small class="ps-2">Selecciona un tipo de propiedad. <span class="text-danger">Requerido
                      *</span></small>
                </div>
                <div class="d-flex flex-column gap-2 w-50">
                  <ng-select formControlName="status" placeholder="Estatus" [clearable]="false" class="w-100 h-50px">
                    @for (item of this.statusProperties; track $index) {
                    <ng-option [value]="item.propertyStatusId">{{ item.label }}</ng-option>
                    }
                  </ng-select>
                  <small class="ps-2">Selecciona un estatus para la propiedad. <span class="text-danger">Requerido
                      *</span></small>
                </div>
              </div>
            </div>
          </div>

          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Ubicación</h6>
            </div>
            <div class="card-body">
              <div class="mb-3 d-flex gap-2">
                <div class="d-flex flex-column gap-2 w-50">
                  <ng-select formControlName="state" (change)="onChangeState()" placeholder="Estado" [clearable]="false"
                    class="w-100 h-50px">
                    @for (item of this.states; track $index) {
                    <ng-option [value]="item.code">{{ item.label }}</ng-option>
                    }
                  </ng-select>
                  <small class="ps-2">Estado.</small>
                </div>
                <div class="d-flex flex-column gap-2 w-50">
                  <ng-select formControlName="municipality" placeholder="Municipio" [clearable]="false"
                    class="w-100 h-50px">
                    @for (item of this.municipalities; track $index) {
                    <ng-option [value]="item.stateMunicipalityId">{{ item.label }}</ng-option>
                    }
                  </ng-select>
                  <small class="ps-2">Municipio.</small>
                </div>
              </div>
              <div class="d-flex gap-2">
                <div class="d-flex flex-column gap-2 w-25">
                  <input formControlName="city" placeholder="Ciudad" type="text" pInputText class="w-100 h-50px"
                    pKeyFilter="alpha" />
                  <small class="ps-2">Ciudad.</small>
                </div>
                <div class="d-flex flex-column gap-2 w-25">
                  <input formControlName="zipcode" placeholder="Código Postal" type="text" pInputText
                    class="w-100 h-50px" pKeyFilter="num" />
                </div>
                <div class="d-flex flex-column gap-2 w-50">
                  <input placeholder="Dirección" formControlName="address" type="text" pInputText
                    class="w-100 h-50px" />
                  <small class="ps-2">Dirección.</small>
                </div>
              </div>
            </div>
          </div>

          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Información de la propiedad</h6>
            </div>
            <div class="card-body">
              <div class="mb-3 d-flex gap-4">
                <div class="d-flex flex-column gap-2 w-50">
                  <p-inputNumber formControlName="squareMetres" [step]="1" [min]="0" [maxFractionDigits]="2"
                    [showButtons]="true" buttonLayout="horizontal" decrementButtonClass="p-button-primary me-1"
                    decrementButtonIcon="pi pi-minus" incrementButtonClass="p-button-primary ms-1"
                    incrementButtonIcon="pi pi-plus" styleClass="w-100" inputStyleClass="text-center"></p-inputNumber>
                  <small class="text-center">Metros cuadrados de terreno.</small>
                </div>
                <div class="d-flex flex-column gap-2 w-50">
                  <p-inputNumber formControlName="bedrooms" [step]="1" [min]="0" [maxFractionDigits]="2"
                    [showButtons]="true" buttonLayout="horizontal" decrementButtonClass="p-button-primary me-1"
                    decrementButtonIcon="pi pi-minus" incrementButtonClass="p-button-primary ms-1"
                    incrementButtonIcon="pi pi-plus" styleClass="w-100" inputStyleClass="text-center"></p-inputNumber>
                  <small class="text-center">Número de recamaras.</small>
                </div>
              </div>
              <div class="mb-3 d-flex gap-4">
                <div class="d-flex flex-column gap-2 w-50">
                  <p-inputNumber formControlName="bathrooms" [step]="1" [min]="0" [maxFractionDigits]="2"
                    [showButtons]="true" buttonLayout="horizontal" decrementButtonClass="p-button-primary me-1"
                    decrementButtonIcon="pi pi-minus" incrementButtonClass="p-button-primary ms-1"
                    incrementButtonIcon="pi pi-plus" styleClass="w-100" inputStyleClass="text-center"></p-inputNumber>
                  <small class="text-center">Número de baños.</small>
                </div>
                <div class="d-flex flex-column gap-2 w-50">
                  <p-inputNumber formControlName="floors" [step]="1" [min]="0" [maxFractionDigits]="2"
                    [showButtons]="true" buttonLayout="horizontal" decrementButtonClass="p-button-primary me-1"
                    decrementButtonIcon="pi pi-minus" incrementButtonClass="p-button-primary ms-1"
                    incrementButtonIcon="pi pi-plus" styleClass="w-100" inputStyleClass="text-center"></p-inputNumber>
                  <small class="text-center">Número de pisos.</small>
                </div>
              </div>
              <div class="d-flex gap-4">
                <div class="d-flex flex-column gap-2 w-25">
                  <ng-select formControlName="currency" placeholder="Moneda" [clearable]="false" class="w-100 h-50px">
                    @for (item of this.currencies; track $index) {
                    <ng-option [value]="item.value">{{ item.label }}</ng-option>
                    }
                  </ng-select>
                  <small class="ps-2">Moneda.</small>
                </div>
                <div class="d-flex flex-column gap-2 w-25">
                  <p-inputNumber formControlName="price" mode="currency" [currency]="this.currency.value" locale="es-MX"
                    inputStyleClass="w-100 h-50px" styleClass=" w-100" placeholder="$0.00"></p-inputNumber>
                  <small class="ps-2">Precio</small>
                </div>
              </div>
            </div>
          </div>

          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Descripción detallada.</h6>
            </div>
            <div class="card-body">
              <p-editor formControlName="content" [style]="{ height: '400px' }"></p-editor>
            </div>
          </div>

          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Galería.</h6>
              <small class="d-block">Formatos JPG, JPEG y PNG. Tamaño máximo 2mb.</small>
            </div>
            <div class="card-body">
              <div class="row">
                @for (item of this.gallery.controls; track item; let index = $index) {
                <div formArrayName="gallery" class="col-md-3 mb-4">
                  <div class="card page-section rounded-2 shadow-sm">
                    <div class="card-body p-0">
                      @if (getImageAsIndex(index)?.flag === true) {
                      <div class="d-flex flex-column gap-2">
                        <preview-upload-image imageSrc="{{getImageAsIndex(index)?.value}}">
                        </preview-upload-image>
                      </div>

                      <div class="end-0 position-absolute top-0" style="margin: -10px;">
                        <p-button icon="pi pi-trash" severity="danger" [rounded]="true" size="small"
                          (click)="removeImage(index)"></p-button>
                      </div>
                      }
                      @else {
                      <div class="d-flex flex-column gap-2">
                        <preview-upload-image [image]="getImageAsIndex(index)?.value"
                          imageSrc="./assets/images/media-placeholder.png">
                          <input #input (change)="onInputFileChanged($event, index)" class="form-control h-50px"
                            type="file" style="line-height: 2.6;">
                        </preview-upload-image>
                      </div>

                      <div class="end-0 position-absolute top-0" style="margin: -10px;">
                        <p-button icon="pi pi-trash" severity="danger" [rounded]="true" size="small"
                          (click)="removeImage(index)"></p-button>
                      </div>
                      }
                    </div>
                  </div>
                </div>
                }

                <div class="col-md-3 mb-4">
                  <div (click)="pushImage()"
                    class="button-add-image align-items-center d-flex flex-column h-100 justify-content-center w-100 border-2 border rounded-2 py-5"
                    style="border-style: dashed !important;" role="button">
                    <div class="opacity-25">
                      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor"
                        class="bi bi-plus-lg" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Imágen de portada.</h6>
            </div>
            <div class="card-body">
              <div class="d-flex flex-column gap-2">
                <preview-upload-image [image]="this.hero.value" [imageSrc]="this.data.hero.uri">
                  <input #input (change)="onHeroChanged($event)" class="form-control h-50px" type="file"
                    style="line-height: 2.6;">
                </preview-upload-image>
                <small class="ps-2 pt-1 d-block">Formatos JPG, JPEG y PNG. Tamaño máximo 2mb. <span
                    class="text-danger">Requerido *</span></small>
              </div>
            </div>
          </div>

          <div formGroupName="metadata" class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Metadata.</h6>
            </div>
            <div class="card-body">
              <p class="mb-4">Esta información es especifica para los buscadores y compartir el contenido en redes
                sociales.</p>

              <div class="mb-3 d-flex flex-column gap-2">
                <preview-upload-image [image]="this.metadataHero.value"
                  imageSrc="./assets/images/media-placeholder.png">
                  <input #input (change)="onMetadataHeroChanged($event)" class="form-control h-50px" type="file"
                    style="line-height: 2.6;">
                </preview-upload-image>
                <small class="ps-2 pt-1 d-block">Formatos JPG, JPEG y PNG. Tamaño máximo 2mb.</small>
              </div>
              <div class="mb-3 d-flex flex-column gap-2">
                <input placeholder="Título de la página." formControlName="title" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="px-2">Escribe un título.</small>
              </div>
              <div class="mb-3 d-flex flex-column gap-2">
                <input placeholder="Descripción." formControlName="description" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="px-2">Escribe una breve descripción.</small>
              </div>
              <div class="d-flex flex-column gap-2">
                <p-chips placeholder="Palabras clave." formControlName="keywords" styleClass="d-block"
                  inputStyleClass="d-block w-100" [separator]="this.utils.separatorExp"></p-chips>
                <small class="px-2">Escribe palabras clave para los motores de busqueda.</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    }
  </div>
</backbone>