import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicPagesCreatePageRoutingModule } from './dynamic-pages-create-routing.module';
import { DynamicPagesCreatePage } from './dynamic-pages-create.component';
import { HeaderPageTitleBoxComponent } from '@components/headers/page-title-box/page-title-box.component';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { RouterModule } from '@angular/router';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { PlaceholderCardFormVerticalComponent } from '@components/placeholders/card-form-vertical/card-form-vertical.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from 'primeng/editor';
import { NgSelectModule } from '@ng-select/ng-select';
import { PreviewUploadImageComponent } from '@components/preview-upload-image/preview-upload-image.component';
import { ChipsModule } from 'primeng/chips';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  declarations: [
    DynamicPagesCreatePage
  ],
  imports: [
    CommonModule,
    DynamicPagesCreatePageRoutingModule,
    ReactiveFormsModule,
    RouterModule,
    BackboneComponent,
    HeaderPageTitleBoxComponent,
    PlaceholderCardFormVerticalComponent,
    InputTextModule,
    ButtonModule,
    EditorModule,
    NgSelectModule,
    PreviewUploadImageComponent,
    ChipsModule,
    FormsModule,
    InputTextareaModule
  ]
})
export class DynamicPagesCreatePageModule { }
