import { Component } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicPagesService } from '@services/dynamic-pages.service';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';
import { ToastService } from '@utils/toast.service';
import { UtilsService } from '@utils/utils.service';

@Component({
  selector: 'app-dynamic-pages-view',
  templateUrl: './dynamic-pages-view.component.html',
  styleUrl: './dynamic-pages-view.component.scss'
})
export class DynamicPagesViewPage {
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private dynamicPagesService: DynamicPagesService,
    private titleService: Title,
    private toast: ToastService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    public utils: UtilsService
  ) { }

  public isLoading = false;
  public isSaving = false;
  public isDeleting = false;

  public titlePage = '';
  public page: any = null;

  public formPage = this.formBuilder.group({
    title: [null, [Validators.required]],
    url: [null],
    type: [null, [Validators.required]],
    hero: [null],
    metadata: this.formBuilder.group({
      hero: [null],
      title: [null],
      description: [null],
      keywords: [null]
    }),
    sections: this.formBuilder.array([])
  });

  get title() { return this.formPage.get('title') }
  set title(value: any) { this.title.setValue(value) }
  get url() { return this.formPage.get('url') }
  set url(value: any) { this.url.setValue(value) }
  get type() { return this.formPage.get('type') }
  set type(value: any) { this.type.setValue(value) }
  get hero() { return this.formPage.get('hero') }
  set hero(value: any) { this.hero.setValue(value) }

  get metadata() { return this.formPage.get('metadata') }
  get metadataHero() { return this.metadata?.get('hero') }
  set metadataHero(value: any) { this.metadataHero?.setValue(value) }
  get metadataTitle() { return this.metadata?.get('title') }
  set metadataTitle(value: any) { this.metadataTitle?.setValue(value) }
  get metadataDescription() { return this.metadata?.get('description') }
  set metadataDescription(value: any) { this.metadataDescription?.setValue(value) }
  get metadataKeywords() { return this.metadata?.get('keywords') }
  set metadataKeywords(value: any) { this.metadataKeywords?.setValue(value) }

  get sections(): FormArray { return this.formPage.controls["sections"] as FormArray; }
  sectionContent = (index: number): FormArray => { return this.sections.controls[index] as FormArray; }

  public typePages: any[] = [
    { value: 'home', label: 'Inicio' },
    { value: 'portfolio', label: 'Portafolio' },
    { value: 'services', label: 'Servicios' },
  ]

  public typePageSections: any[] = [
    { value: 'input', label: 'Campo de texto.' },
    { value: 'textarea', label: 'Campo de texto grande.' },
    { value: 'editor', label: 'Editor de texto.' },
  ]

  public formAddSections: { type: string | null, name: string | null }[] = []

  ngOnInit() {
    this.isLoading = true;

    this.activatedRoute.params.subscribe(async routeParams => {
      await this.dynamicPagesService.getPage(routeParams['page'])
        .then((response: any) => this.page = response.content)

      if (this.page.length === 0) {
        this.router.navigate(['/pages'])
        this.toast.error('La página no existe.')
      } else {
        this.titlePage = this.page.title;
        this.titleService.setTitle('Viendo la página: ' + this.titlePage + ' - ' + this.titleService.getTitle());

        this.title = this.page.title;
        this.url = this.page.url;
        this.type = this.page.type;

        if (this.page.metadata.title !== null) {
          this.metadataTitle = this.page.metadata.title;
        }

        if (this.page.metadata.description !== null) {
          this.metadataDescription = this.page.metadata.description;
        }

        if (this.page.metadata.keywords !== null) {
          this.metadataKeywords = this.page.metadata.keywords.split(',');
        }

        if (this.page.data) {
          for (const [index, item] of this.page.data.entries()) {
            this.pushSection()

            for (const item2 of item) {
              (this.sections.controls[index] as FormArray)
                .push(this.formBuilder.group({
                  type: [item2.type, Validators.required],
                  name: [item2.name, Validators.required],
                  value: [item2.value]
                }))
            }
          }
        }

        this.isLoading = false;
      }
    });
  }

  onHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.hero = event.target.files[0];
    }
  }

  onMetadataHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.metadataHero = event.target.files[0];
    }
  }

  pushSection = () => {
    this.sections.push(this.formBuilder.array([]))
    this.formAddSections.push({ type: null, name: null })
  }

  removeSection = (index: number) => {
    this.sections.removeAt(index)
    this.formAddSections.splice(index, 1)
  }

  pushContent = (index: number) => {
    const section: FormArray = this.sections.controls[index] as FormArray;
    section.push(this.formBuilder.group({
      type: [this.formAddSections[index].type, Validators.required],
      name: [this.formAddSections[index].name, Validators.required],
      value: [null]
    }))

    this.formAddSections[index].type = null;
    this.formAddSections[index].name = null;
  }

  removeContent = (index: number, indexContent: number) => {
    const section: FormArray = this.sections.controls[index] as FormArray;

    section.removeAt(indexContent)
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('title', this.title.value)
    myFormData.append('url', this.url.value)
    myFormData.append('type', this.type.value)
    myFormData.append('hero', this.hero.value)

    myFormData.append('metadataHero', this.metadataHero.value)
    myFormData.append('metadataTitle', this.metadataTitle.value)
    myFormData.append('metadataDescription', this.metadataDescription.value)
    myFormData.append('metadataKeywords', this.metadataKeywords.value)

    myFormData.append('sections', JSON.stringify(this.sections.value))

    await this.dynamicPagesService.update(this.page.page, myFormData)
      .then(() => this.toast.success('Página guardada exitosamente.'))
      .catch((error: any) => this.toast.error(error.error.data.message))

    this.isSaving = false;
  }

  delete = (event: Event) => {
    this.isDeleting = true;

    this.confirmDialogService.dialog('¿Está seguro de eliminar esta página?', 'Continuar', 'Cancelar')
      .then(() => {
        this.isLoading = true

        this.dynamicPagesService.delete(this.page.page)
          .then(() => {
            this.toast.success('Se eliminó la página.')
            this.router.navigate(['/pages'])
          })
          .catch((error: any) => {
            this.toast.error(error.error.data.message)
            this.isDeleting = false
            this.isLoading = false
          })
      })
      .catch(() => this.isDeleting = false)
  }
}
