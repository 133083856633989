import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DynamicPagesCreatePage } from './dynamic-pages-create.component';
import { authGuard } from '@guards/auth.guard';
import { hasRoleGuard } from '@guards/has-role.guard';

const routes: Routes = [
  {
    path: 'pages/create',
    component: DynamicPagesCreatePage,
    canActivate: [authGuard, hasRoleGuard(null, ['create_dynamic_pages'])],
    data: {
      title: "Crear una nueva página."
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DynamicPagesCreatePageRoutingModule { }
