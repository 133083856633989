<backbone [currentPage]="'user-create'">
  <header-page-title-box [title]="'Crear nueva cuenta de usuario'"
    [links]="[{title: 'Usuarios',  link: '/users'}, {title: 'Crear cuenta'}]">
    <div class="d-flex gap-2" buttons>
      <p-button size="small" icon="pi pi-save" label="Crear" [loading]="isSaving" (onClick)="save()"
        [disabled]="!formUser.valid"></p-button>
    </div>
  </header-page-title-box>
  <div class="p-3">
    @if (this.isLoading === true) {
    <placeholder-card-form-vertical />
    }
    @else {
    <form [formGroup]="formUser" [ngClass]="{'opacity-50 pointer-events user-select-none': this.isSaving === true}">
      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información de la cuenta de usuario.</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 row">
            <label for="inputEmail" class="col-sm-2 col-form-label d-flex align-items-center">Correo electronico</label>
            <div class="col-sm-10">
              <input formControlName="email" type="email" pInputText class="w-100 h-50px"
                placeholder="example@domain.com" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputName" class="col-sm-2 col-form-label d-flex align-items-center">Nombre(s)</label>
            <div class="col-sm-10">
              <input formControlName="firstName" type="text" pInputText class="w-100 h-50px" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputName" class="col-sm-2 col-form-label d-flex align-items-center">Apellidos</label>
            <div class="col-sm-10">
              <input formControlName="lastName" type="text" pInputText class="w-100 h-50px" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputPhone" class="col-sm-2 col-form-label d-flex align-items-center">Número de télefono</label>
            <div class="col-sm-10">
              <p-inputMask formControlName="phone" mask="(999) 999-9999" placeholder="(999) 999-9999"
                styleClass="w-100 h-50px"></p-inputMask>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputLevel" class="col-sm-2 col-form-label d-flex align-items-center">Nivel de acceso</label>
            <div class="col-sm-10">
              <div class="input-group has-validation">
                <ng-select formControlName="permissionId" id="inputLevel" [placeholder]="''" [clearable]="false"
                  class="w-100 h-50px">
                  @for (item of permissions; track $index) {
                  <ng-option [value]="item.permissionId">{{ item.description }}</ng-option>
                  }
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Actualizar contraseña.</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 row">
            <label for="inputPassword" class="col-sm-2 col-form-label d-flex align-items-center">Contraseña</label>
            <div class="col-sm-10">
              <p-password formControlName="password" styleClass="w-100" inputStyleClass="w-100 h-50px"
                [toggleMask]="true"></p-password>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputRPassword" class="col-sm-2 col-form-label d-flex align-items-center">Repetir
              contraseña</label>
            <div class="col-sm-10">
              <p-password formControlName="rpassword" styleClass="w-100" inputStyleClass="w-100 h-50px"
                [toggleMask]="true"></p-password>
            </div>
          </div>
        </div>
      </div>
    </form>
    }
  </div>
</backbone>