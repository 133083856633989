import { FormControl } from "@angular/forms";

export function requiredFileType(_types: Array<string>) {
  return function (control: FormControl) {
    const file = control.value;

    if (file) {
      const types = _types.map(element => element.toLowerCase());
      const extension = file.name.split('.')[1].toLowerCase();

      if (!types.includes(extension.toLowerCase())) {
        return {
          requiredFileType: true
        };
      }

      return null;
    }

    return null;
  };
}

export function requiredFileSize(size: Number = 2000000) {
  return function (control: FormControl) {
    const file = control.value;

    if (file) {
      if (file.size > size) {
        return {
          requiredFileSize: true
        };
      }

      return null;
    }

    return null;
  };
}
