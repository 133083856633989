import { Component } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CatalogsService } from '@services/catalogs.service';
import { RealestateService } from '@services/realestate.service';
import { requiredFileType } from '@utils/validators';
import { ToastService } from '@utils/toast.service';
import { UtilsService } from '@utils/utils.service';

@Component({
  selector: 'page-realestate-create',
  templateUrl: './realestate-create.component.html',
  styleUrl: './realestate-create.component.scss'
})
export class RealEstateCreatePage {
  constructor(
    private formBuilder: FormBuilder,
    public utils: UtilsService,
    private toast: ToastService,
    private router: Router,
    private realestateService: RealestateService,
    private catalogsService: CatalogsService
  ) { }

  public isLoading = false;
  public isSaving = false;

  public typeProperties: any[] = []
  public statusProperties: any[] = []
  public states: any[] = []
  public municipalities: any[] = []
  public currencies: any[] = [
    { value: 'USD', label: 'Dólares americanos' },
    { value: 'MXN', label: 'Pesos mexicanos' },
    { value: 'EUR', label: 'Euros' }
  ]

  public form = this.formBuilder.group({
    name: [null, [Validators.required]],
    foil: [null],
    hero: [null, [
      Validators.required,
      requiredFileType(['png', 'jpg', 'jpeg'])
    ]],
    type: [null, [Validators.required]],
    status: [null, [Validators.required]],
    state: [null],
    municipality: [null],
    city: [null],
    address: [null],
    zipcode: [null],
    squareMetres: [0],
    bedrooms: [0],
    bathrooms: [0],
    floors: [0],
    currency: ['MXN'],
    price: [null],
    content: [null],
    metadata: this.formBuilder.group({
      hero: [null, [
        requiredFileType(['png', 'jpg', 'jpeg'])
      ]],
      title: [null],
      description: [null],
      keywords: [null]
    }),
    gallery: this.formBuilder.array([])
  });

  get name() { return this.form.get('name') }
  set name(value: any) { this.name.setValue(value) }
  get foil() { return this.form.get('foil') }
  set foil(value: any) { this.foil.setValue(value) }
  get hero() { return this.form.get('hero') }
  set hero(value: any) { this.hero.setValue(value) }
  get type() { return this.form.get('type') }
  set type(value: any) { this.type.setValue(value) }
  get status() { return this.form.get('status') }
  set status(value: any) { this.status.setValue(value) }
  get state() { return this.form.get('state') }
  set state(value: any) { this.state.setValue(value) }
  get municipality() { return this.form.get('municipality') }
  set municipality(value: any) { this.municipality.setValue(value) }
  get city() { return this.form.get('city') }
  set city(value: any) { this.city.setValue(value) }
  get address() { return this.form.get('address') }
  set address(value: any) { this.address.setValue(value) }
  get zipcode() { return this.form.get('zipcode') }
  set zipcode(value: any) { this.zipcode.setValue(value) }
  get squareMetres() { return this.form.get('squareMetres') }
  set squareMetres(value: any) { this.squareMetres.setValue(value) }
  get bedrooms() { return this.form.get('bedrooms') }
  set bedrooms(value: any) { this.bedrooms.setValue(value) }
  get bathrooms() { return this.form.get('bathrooms') }
  set bathrooms(value: any) { this.bathrooms.setValue(value) }
  get floors() { return this.form.get('floors') }
  set floors(value: any) { this.floors.setValue(value) }
  get currency() { return this.form.get('currency') }
  set currency(value: any) { this.currency.setValue(value) }
  get price() { return this.form.get('price') }
  set price(value: any) { this.price.setValue(value) }
  get content() { return this.form.get('content') }
  set content(value: any) { this.content.setValue(value) }

  get metadata() { return this.form.get('metadata') }
  get metadataHero() { return this.metadata?.get('hero') }
  set metadataHero(value: any) { this.metadataHero?.setValue(value) }
  get metadataTitle() { return this.metadata?.get('title') }
  set metadataTitle(value: any) { this.metadataTitle?.setValue(value) }
  get metadataDescription() { return this.metadata?.get('description') }
  set metadataDescription(value: any) { this.metadataDescription?.setValue(value) }
  get metadataKeywords() { return this.metadata?.get('keywords') }
  set metadataKeywords(value: any) { this.metadataKeywords?.setValue(value) }

  get gallery(): FormArray { return this.form.controls["gallery"] as FormArray; }
  getImageAsIndex = (index: number) => this.gallery.controls[index].get('file')

  async ngOnInit() {
    this.isLoading = true;

    this.pushImage()

    this.realestateService.getTypeList()
      .then((response: any) => this.typeProperties = response.content)

    this.realestateService.getStatusList()
      .then((response: any) => this.statusProperties = response.content)

    this.catalogsService.states()
      .then((response: any) => this.states = response)

    this.isLoading = false;
  }

  onHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.hero = event.target.files[0];
    }
  }

  onMetadataHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.metadataHero = event.target.files[0];
    }
  }

  onChangeState = () => {
    this.municipality = null;
    this.catalogsService.municipality(this.state.value)
      .then((response: any) => this.municipalities = response)
  }

  pushImage = () => this.gallery.push(this.formBuilder.group({ file: [null, [requiredFileType(['png', 'jpg', 'jpeg'])]] }))
  removeImage = (index: number) => {
    this.gallery.removeAt(index);

    if (this.gallery.value <= 0) {
      this.pushImage()
    }
  }

  onInputFileChanged = (event: any, index: number) => {
    if (event.target.files && event.target.files.length) {
      this.gallery.controls[index].get('file')?.setValue(event.target.files[0]);
    }
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('name', this.name.value)
    myFormData.append('foil', this.foil.value)
    myFormData.append('hero', this.hero.value)
    myFormData.append('type', this.type.value)
    myFormData.append('status', this.status.value)
    myFormData.append('state', this.state.value)
    myFormData.append('municipality', this.municipality.value)
    myFormData.append('city', this.city.value)
    myFormData.append('address', this.address.value)
    myFormData.append('zipcode', this.zipcode.value)
    myFormData.append('squareMetres', this.squareMetres.value)
    myFormData.append('bedrooms', this.bedrooms.value)
    myFormData.append('bathrooms', this.bathrooms.value)
    myFormData.append('floors', this.floors.value)
    myFormData.append('currency', this.currency.value)
    myFormData.append('price', this.price.value)
    myFormData.append('content', this.content.value)

    myFormData.append('metadataHero', this.metadataHero.value)
    myFormData.append('metadataTitle', this.metadataTitle.value)
    myFormData.append('metadataDescription', this.metadataDescription.value)
    myFormData.append('metadataKeywords', this.metadataKeywords.value)

    for (const [index, item] of this.gallery.value.entries()) {
      myFormData.append(`gallery_${index}`, item.file)
    }

    await this.realestateService.create(myFormData)
      .then(() => {
        this.toast.success('Se agregó la propiedad.')
        this.router.navigate(['/realestate'])
      })
      .catch(() => this.toast.error('Error al crear la propiedad.'))

    this.isSaving = false;
  }
}
