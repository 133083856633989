import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserListPage } from './user-list.component';
import { authGuard } from '@guards/auth.guard';
import { hasRoleGuard } from '@guards/has-role.guard';

const routes: Routes = [
  {
    path: 'users',
    component: UserListPage,
    canActivate: [authGuard, hasRoleGuard(null, ['read_user'])],
    data: {
      title: "Lista de usuarios"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserListPageRoutingModule { }
