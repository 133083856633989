import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogCreatePageModule } from './blog-create/blog-create.module';
import { BlogListPageModule } from './blog-list/blog-list.module';
import { BlogViewPageModule } from './blog-view/blog-view.module';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    BlogCreatePageModule,
    BlogListPageModule,
    BlogViewPageModule
  ],
  exports: [RouterModule]
})
export class BlogRoutingModule { }
