import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DynamicPagesViewPage } from './dynamic-pages-view.component';
import { authGuard } from '@guards/auth.guard';
import { hasRoleGuard } from '@guards/has-role.guard';

const routes: Routes = [
  {
    path: 'pages/:page',
    component: DynamicPagesViewPage,
    canActivate: [authGuard, hasRoleGuard(null, ['update_dynamic_pages'])]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DynamicPagesViewPageRoutingModule { }
