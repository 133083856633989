<div class="notifications-box-bell">
  <div class="p-3 border-bottom border-1">
    <div class="d-flex align-items-center justify-content-between">
      <p class="mb-0 fs-17 fw-semibold">Notificaciones</p>
      <span><button class="btn btn-light btn-sm">Ver todo</button></span>
    </div>
  </div>

  <div class="list-group rounded-0">
    @if (this.notifications.length <= 0) { <div class="empty opacity-25 p-4 text-center fw-medium user-select-none">
      <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-bell-fill"
        viewBox="0 0 16 16">
        <path
          d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
      </svg>
      <p class="m-0 mt-2">Sin notificaciones.</p>
  </div>
  }

  @for (item of this.notifications; track item) {
  <a [routerLink]="[item.url]" class="list-group-item border-0" (click)="onClickNotification(item.id); item.status = 1"
    [ngClass]="item.status === 0 ? 'notification-unread text-info' : 'notification-read text-black-50'">
    <div class="d-flex w-100 justify-content-between">
      <h6 class="mb-1 fs-base" [ngClass]="item.status == 0 ? 'fw-medium' : 'fw-normal'">{{item.title}}</h6>
      <small [ngClass]="item.status == 0 ? 'fw-medium' : 'fw-normal'">{{item.dateCreate.split(' ')[1]}}
        {{item.dateCreate.split(' ')[2]}}</small>
    </div>
    <p class="mb-1" [ngClass]="item.status == 0 ? 'fw-medium' : 'fw-normal'">{{item.message}}</p>
    <small [ngClass]="item.status == 0 ? 'fw-medium' : 'fw-normal'">{{item.description}}</small>
  </a>
  }
</div>

<div class="p-3 border-top border-1">
  <p class="m-0 text-center text-muted"><small>Powered by CodeMonkey</small></p>
</div>
</div>