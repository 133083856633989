import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';
import { Router } from '@angular/router';
import { SessionService } from './session.service';

/**
 * Interface representing the access authentication credentials.
 */
export interface IAccessAuth {
  email: string,
  password: string
}

/**
 * Service responsible for handling authentication-related functionality.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpService,
    private router: Router,
    private session: SessionService
  ) { }

  /**
   * Logs in the user with the provided credentials.
   * @param body - The access authentication information.
   * @returns A promise that resolves with the login response.
   */
  login = (body: IAccessAuth): Promise<any> => {
    return new Promise((resolve, reject) => this.http.post(`/api/v1/login`, body, false, {}, false)
      .then(response => resolve(response))
      .catch(error => reject(error)))
  }

  /**
   * Logs out the user by clearing the session and navigating to the home page.
   */
  logout = (): void => {
    this.session.clear()
    this.router.navigate(['/login'])
  }

  /**
   * Locks the screen by removing the token and navigating to the lockscreen page.
   */
  lockscreen = (): void => {
    this.session.removeToken()
    this.router.navigate(['/lockscreen'])
  }

  /**
   * Sets the authentication session by saving the token and user information.
   * @param data - The authentication data containing the token and type.
   * @returns void
   */
  setAuthSession = (data: any): void => {
    this.session.saveToken(data.authorization.token, data.authorization.type)
    this.session.saveUser(data.user)
  }
}
