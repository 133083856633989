import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryRoutingModule } from './gallery-routing.module';
import { GalleryListPageModule } from './gallery-list/gallery-list.module';
import { GalleryCreatePageModule } from './gallery-create/gallery-create.module';
import { GalleryViewModule } from './gallery-view/gallery-view.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GalleryRoutingModule,
    GalleryListPageModule,
    GalleryCreatePageModule,
    GalleryViewModule
  ]
})
export class GalleryModule { }
