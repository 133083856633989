import { Component, ContentChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'preview-upload-image',
  standalone: true,
  imports: [
    SkeletonModule
  ],
  templateUrl: './preview-upload-image.component.html',
  styleUrl: './preview-upload-image.component.scss'
})
export class PreviewUploadImageComponent {
  @Input() image: any;
  @Input() imageSrc: any;
  public isLoading: boolean = false;

  @ContentChild("input")
  public input!: ElementRef;

  async ngOnChanges(changes: SimpleChanges) {
    this.isLoading = true;

    if (changes['image'] && changes['image'].currentValue) {
      this.imageSrc = await this.getBase64(changes['image'].currentValue);
    }

    setTimeout(() => this.isLoading = false, 100);
  }

  click = () => (this.input) ? this.input.nativeElement.click() : null;

  getBase64 = (file: any) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = e => resolve(reader.result);
      reader.readAsDataURL(file);
    })
  }
}
