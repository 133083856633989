<backbone [currentPage]="'blog-list'">
  <header-page-title-box title="Lista de entradas." [links]="[{title: 'Blog',  link: '/blog'}, {title: 'Lista'}]">
    <div buttons>
      <p-button *showForPermissions="['create_blog']" size="small" icon="pi pi-plus" label="Nueva entrada"
        [routerLink]="['/blog/create']"></p-button>
    </div>
  </header-page-title-box>
  <div class="p-3">
    @if (this.isLoading === true) {
    <section class="page-section bg-white rounded-2 shadow-sm p-3">
      <placeholder-table />
    </section>
    }
    @else {
    <section class="page-section bg-white rounded-2 shadow-sm">
      <table class="table table-responsive-md table-borderless rounded-2 mb-0">
        <thead class="border-bottom">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Título</th>
            <th scope="col">Tags</th>
            <th scope="col">Fecha de creación</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          @if (this.blogs.content.length > 0) {
          @for (item of this.blogs.content; track $index) {
          <tr>
            <td class="align-middle fw-medium text-muted">
              {{item.blogId}}
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.title}}
            </td>
            <td class="align-middle fw-medium text-muted">
              @if (item.tags.split(',').length > 0) {
              @for (item of item.tags.split(','); track $index) {
              <p-chip class="me-1" label="{{item}}" icon="pi pi-tags"></p-chip>
              }
              }
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.dateCreated.ISO8601 | date: 'longDate' }}
            </td>
            <td class="align-middle fw-medium text-nowrap text-end">
              <div class="d-flex justify-content-end gap-1">
                <p-button icon="pi pi-eye" [routerLink]="['/blog', item.url]"></p-button>
              </div>
            </td>
          </tr>
          }
          }
          @else {
          <tr>
            <td scope="row" colspan="5" class="align-middle fw-medium text-center">
              <p class="m-0 p-4 opacity-50">Sin datos</p>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </section>
    }
  </div>
</backbone>