import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicPagesListPageRoutingModule } from './dynamic-pages-list-routing.module';
import { DynamicPagesListPage } from './dynamic-pages-list.component';
import { HeaderPageTitleBoxComponent } from '@components/headers/page-title-box/page-title-box.component';
import { PlaceholderTableComponent } from '@components/placeholders/table/table.component';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';

@NgModule({
  declarations: [
    DynamicPagesListPage
  ],
  imports: [
    CommonModule,
    DynamicPagesListPageRoutingModule,
    RouterModule,
    BackboneComponent,
    PlaceholderTableComponent,
    HeaderPageTitleBoxComponent,
    ButtonModule,
    ChipModule,
    ShowForPermissionsDirective
  ]
})
export class DynamicPagesListPageModule { }
