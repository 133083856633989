import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  constructor(
    private http: HttpService
  ) { }

  public getList = async () => await this.http.get(`/api/v1/blog`)
    .then((response: any) => response.data)

  public getBlog = async (blog: string) => await this.http.get(`/api/v1/blog/${blog}?by=url`)
    .then((response: any) => response.data)

  public create = async (body: any) => await this.http.post(`/api/v1/blog`, body)
    .then((response: any) => response)

  public save = async (blog: string, body: any) => await this.http.put(`/api/v1/blog/${blog}?by=url`, body)
    .then((response: any) => response)

  public delete = async (blog: string) => await this.http.delete(`/api/v1/blog/${blog}?by=url`)
    .then((response: any) => response.data)
}
