import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlogService } from '@services/blog.service';
import { ToastService } from '@utils/toast.service';

@Component({
  selector: 'page-blog-create',
  templateUrl: './blog-create.component.html',
  styleUrl: './blog-create.component.scss'
})
export class BlogCreatePage {
  constructor(
    private formBuilder: FormBuilder,
    private blogService: BlogService,
    private toast: ToastService,
    private router: Router
  ) { }

  public isLoading = false;
  public isSaving = false;
  public separatorExp: RegExp = /,| /;

  public formBlog = this.formBuilder.group({
    title: [null, Validators.required],
    description: [null, Validators.required],
    hero: [null, Validators.required],
    content: [null],
    tags: [null, Validators.required],
    seoDescription: [null],
  });

  get title() { return this.formBlog.get('title') }
  set title(value: any) { this.title.setValue(value) }
  get description() { return this.formBlog.get('description') }
  set description(value: any) { this.description.setValue(value) }
  get hero() { return this.formBlog.get('hero') }
  set hero(value: any) { this.hero.setValue(value) }
  get content() { return this.formBlog.get('content') }
  set content(value: any) { this.content.setValue(value) }
  get tags() { return this.formBlog.get('tags') }
  set tags(value: any) { this.tags.setValue(value) }
  get seoDescription() { return this.formBlog.get('seoDescription') }
  set seoDescription(value: any) { this.seoDescription.setValue(value) }

  public onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      this.hero = event.target.files[0];
    }
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('title', this.title.value)
    myFormData.append('description', this.description.value)
    myFormData.append('hero', this.hero.value)
    myFormData.append('content', this.content.value)
    myFormData.append('tags', this.tags.value)
    myFormData.append('seoDescription', this.seoDescription.value)

    await this.blogService.create(myFormData)
      .then(() => {
        this.toast.success('Entrada al blog, agregada.')
        this.router.navigate(['/blog'])
      })
      .catch(() => this.toast.error('Error al crear la entrada al blog.'))

    this.isSaving = false;
  }
}
