import { Component } from '@angular/core';
import { GalleryService } from '@services/gallery.service';

@Component({
  selector: 'page-gallery-list',
  templateUrl: './gallery-list.component.html',
  styleUrl: './gallery-list.component.scss'
})
export class GalleryListPage {
  public isLoading = true;

  constructor(
    private galleryService: GalleryService
  ) { }

  async ngOnInit() {
    this.refresh();
  }

  refresh = () => {
    this.isLoading = true;
    this.getList();
  }

  public list: any = [];
  getList = async () => {
    await this.galleryService.getList()
      .then((response) => this.list = response.content)

    this.isLoading = false
  }
}
