<backbone [currentPage]="'user-list'">
  <header-page-title-box [title]="'Lista de usuarios registrados'"
    [links]="[{title: 'Usuarios',  link: '/users'}, {title: 'Lista'}]">
    <div buttons>
      <p-button *showForPermissions="['create_user']" size="small" icon="pi pi-user-plus" label="Nuevo usuario"
        [routerLink]="['/users/create']"></p-button>
    </div>
  </header-page-title-box>
  <div class="p-3">
    @if (this.isLoading === true) {
    <section class="page-section bg-white rounded-2 shadow-sm p-3">
      <placeholder-table />
    </section>
    }
    @else {
    <section class="page-section bg-white rounded-2 shadow-sm">
      <table class="table table-responsive-md table-borderless rounded-2 mb-0">
        <thead class="border-bottom">
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Correo electrónico</th>
            <th scope="col">Usuario</th>
            <th scope="col" class="text-center">Rol</th>
            <th scope="col">Fecha de creación</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          @if (this.users.length > 0) {
          @for (item of this.users; track $index) {
          <tr>
            <td class="align-middle fw-medium text-muted">
              {{item.firstName}} {{item.lastName}}
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.email}}
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.username}}
            </td>
            <td class="align-middle fw-medium text-muted text-center">
              <small class="text-bg-secondary p-1 px-3 rounded-2">{{findRole(item.permissionId)?.description}}</small>
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.dateCreated.ISO8601 | date: 'longDate' }}
            </td>
            <td class="align-middle fw-medium text-nowrap text-end">
              <div class="d-flex justify-content-end gap-1">
                <p-button *showForPermissions="['update_user']" icon="pi pi-eye"
                  [routerLink]="['/user', item.username]"></p-button>
              </div>
            </td>
          </tr>
          }
          }
          @else {
          <tr>
            <td scope="row" colspan="6" class="align-middle fw-medium text-center">
              <p class="m-0 p-4 opacity-50">Sin datos</p>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </section>
    }
  </div>
</backbone>