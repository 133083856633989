import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GalleryCreatePage } from './gallery-create.component';
import { authGuard } from '@guards/auth.guard';
import { hasRoleGuard } from '@guards/has-role.guard';

const routes: Routes = [
  {
    path: 'galleries/create',
    component: GalleryCreatePage,
    canActivate: [authGuard, hasRoleGuard(null, ['create_galleries'])],
    data: {
      title: "Crear una nueva galería."
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GalleryCreatePageRoutingModule { }
