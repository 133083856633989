<backbone [currentPage]="'blog-create'">
  <header-page-title-box title="Nueva entrada al blog."
    [links]="[{title: 'Blog',  link: '/blog'}, {title: 'Crear blog'}]">
    <div class="d-flex gap-2" buttons>
      <p-button size="small" icon="pi pi-save" label="Crear" [loading]="isSaving" (onClick)="save()"
        [disabled]="!formBlog.valid"></p-button>
    </div>
  </header-page-title-box>
  <div class="p-3">
    @if (this.isLoading === true) {
    <placeholder-card-form-vertical />
    }
    @else {
    <form [formGroup]="formBlog" [ngClass]="{'opacity-50 pointer-events user-select-none': this.isSaving === true}">
      <div class="row">
        <div class="col-md-8">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Información de la cuenta de usuario.</h6>
            </div>
            <div class="card-body">
              <div class="mb-3 flex flex-column gap-2">
                <input placeholder="Título para la entrada" formControlName="title" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="ps-2">Título para la entrada de tu blog. <i class="text-muted">Ejem: Mi
                    primer blog.</i> <span class="text-danger ms-2">Requerido *</span></small>
              </div>
              <div class="flex flex-column gap-2">
                <input placeholder="Descripción" formControlName="description" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="ps-2">Descripción corta, para previsualizar la entrada. <span
                    class="text-danger ms-2">Requerido *</span></small>
              </div>
            </div>
          </div>
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Contenido del blog. <small class="text-danger ms-2">Requerido
                  *</small></h6>
            </div>
            <div class="card-body">
              <p-editor formControlName="content" [style]="{ height: '320px' }"></p-editor>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Imágen de portada.</h6>
            </div>
            <div class="card-body">
              <div class="flex flex-column gap-2">
                <input (change)="onFileChanged($event)" class="form-control h-50px" type="file"
                  style="line-height: 2.6;">
                <small class="ps-2 pt-1 d-block">Se aceptan formatos JPG, JPEG y PNG.</small>
                <small class="ps-2 d-block">Con peso no mayor a 2mb. <span class="text-danger ms-2">Requerido
                    *</span></small>
              </div>
            </div>
          </div>
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Metadatos SEO/SEM</h6>
            </div>
            <div class="card-body">
              <div class="mb-3 flex flex-column gap-2">
                <p-chips placeholder="Tags" formControlName="tags" styleClass="d-block" inputStyleClass="d-block w-100"
                  [separator]="separatorExp"></p-chips>
                <small class="ps-2">Usa tags para etiquetar el contenido de tu entrada. <span
                    class="text-danger ms-2">Requerido *</span></small>
              </div>
              <div class="flex flex-column gap-2">
                <input placeholder="Descripción" formControlName="seoDescription" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="ps-2">Usa una descripción breve para el posicionamiento.</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    }
  </div>
</backbone>