import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DynamicPagesListPage } from './dynamic-pages-list.component';
import { authGuard } from '@guards/auth.guard';
import { hasRoleGuard } from '@guards/has-role.guard';

const routes: Routes = [
  {
    path: 'pages',
    component: DynamicPagesListPage,
    canActivate: [authGuard, hasRoleGuard(null, ['read_dynamic_pages'])],
    data: {
      title: "Lista de páginas"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DynamicPagesListPageRoutingModule { }
