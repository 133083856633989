<backbone [currentPage]="'galleries-list'">
  <header-page-title-box title="Lista de galerías."
    [links]="[{title: 'Galerías',  link: '/galleries'}, {title: 'Lista'}]">
    <div buttons>
      <p-button *showForPermissions="['create_galleries']" size="small" icon="pi pi-plus" label="Nueva galería"
        [routerLink]="['/galleries/create']"></p-button>
    </div>
  </header-page-title-box>
  <div class="p-3">
    @if (this.isLoading === true) {
    <section class="page-section bg-white rounded-2 shadow-sm p-3">
      <placeholder-table />
    </section>
    }
    @else {
    <section class="page-section bg-white rounded-2 shadow-sm">
      <table class="table table-responsive-md table-borderless rounded-2 mb-0">
        <thead class="border-bottom">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Nombre</th>
            <th scope="col">Fecha de creación</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          @for (item of this.list; track $index) {
          <tr>
            <td class="align-middle fw-medium text-muted">
              {{item.gallery}}
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.name}}
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.dateCreated.ISO8601 | date: 'longDate' }}
            </td>
            <td class="align-middle fw-medium text-nowrap text-end">
              <div class="d-flex justify-content-end gap-1">
                <p-button *showForPermissions="['update_galleries']" icon="pi pi-eye"
                  [routerLink]="['/galleries', item.gallery]"></p-button>
              </div>
            </td>
          </tr>
          }
          @empty {
          <tr>
            <td scope="row" colspan="4" class="align-middle fw-medium text-center">
              <p class="m-0 p-4 opacity-50">Sin datos</p>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </section>
    }
  </div>
</backbone>