<backbone [currentPage]="'realestate-list'">
  <header-page-title-box title="Lista de propiedades."
    [links]="[{title: 'Realestate',  link: '/realestate'}, {title: 'Lista'}]">
    <div buttons>
      <p-button *showForPermissions="['create_realestate']" size="small" icon="pi pi-plus" label="Nueva propiedad"
        [routerLink]="['/realestate/create']"></p-button>
    </div>
  </header-page-title-box>
  <div class="p-3">
    @if (this.isLoading === true) {
    <section class="page-section bg-white rounded-2 shadow-sm p-3">
      <placeholder-table />
    </section>
    }
    @else {
    <section class="page-section bg-white rounded-2 shadow-sm">
      <table class="table table-responsive-md table-borderless rounded-2 mb-0">
        <thead class="border-bottom">
          <tr>
            <th scope="col">Folio</th>
            <th scope="col">Propiedad</th>
            <th scope="col" class="text-center">Tipo</th>
            <th scope="col" class="text-center">Estatus</th>
            <th scope="col">Ubicación</th>
            <th scope="col">Precio</th>
            <th scope="col">Fecha de creación</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          @for (item of this.properties.content; track $index) {
          <tr>
            <td class="align-middle fw-medium text-muted">
              {{item.foil}}
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.name}}
            </td>
            <td class="align-middle fw-medium text-muted text-center">
              <small class="text-bg-info p-1 px-3 rounded-2">{{findType(item.typeId)?.label}}</small>
            </td>
            <td class="align-middle fw-medium text-muted text-center">
              <small class="p-1 px-3 rounded-2"
                [ngClass]="{'text-bg-light': item.statusId === 1, 'text-bg-success': item.statusId === 2, 'text-bg-warning': item.statusId === 3, 'text-bg-danger': item.statusId === 4 }">{{findStatus(item.statusId)?.label}}</small>
            </td>
            <td class="align-middle fw-medium text-muted">
              @if (item.stateCode !== null || item.municipalityId !== null || item.city !== null) {
              {{item.stateCode ?? ''}}{{findMunicipality(item.municipalityId)?.label ? ', ':
              ''}}{{findMunicipality(item.municipalityId)?.label ?? ''}}{{item.city ? ', ' : ''}}{{item.city ??
              ''}}{{item.zipcode ? ', ' : ''}}{{item.zipcode ?? ''}}.
              }
              @else {Sin ubicación.}
            </td>
            <td class="align-middle fw-medium text-muted">
              @if ( item.price !== null ) { {{item.price | currency: 'MXN'}} ${{item.currency}} }
              @else {Sin precio.}
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.dateCreated.ISO8601 | date: 'longDate' }}
            </td>
            <td class="align-middle fw-medium text-nowrap text-end">
              <div class="d-flex justify-content-end gap-1">
                <p-button *showForPermissions="['update_realestate']" icon="pi pi-eye"
                  [routerLink]="['/realestate', item.foil]"></p-button>
              </div>
            </td>
          </tr>
          }
          @empty {
          <tr>
            <td scope="row" colspan="8" class="align-middle fw-medium text-center">
              <p class="m-0 p-4 opacity-50">Sin datos</p>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </section>
    }
  </div>
</backbone>